<template>
  <div id="divBusinessPartnersWrapper">
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="landing">
          <BrandLogo :width="170" :height="170" :image="partner.image" />
          <div class="right-area">
            <VueHeadline level="2" weightLevel="6" color="grey-40" class="headline-text"
              >Türkiye’de Philip Morris</VueHeadline
            >
            <VueText sizeLevel="3" color="grey-30" class="text">
              <div v-html="headContent"></div>
            </VueText>
            <div class="people-wrapper">
              <VueGridContainer>
                <VueGridRow>
                  <VueGridCol>
                    <BrandMeetPerson
                      :hrefCall="people.te.tep"
                      :person="people.te"
                      :cornerIcon="icons.phoneAlt"
                    />
                  </VueGridCol>
                </VueGridRow>
              </VueGridContainer>
            </div>
          </div>
        </div>

        <div class="tabs">
          <div
            :class="activeTab == 'visitDays' ? 'active-title' : ''"
            @click="setActiveTab('visitDays')"
          >
            <VueText
              :color="activeTab === 'visitDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >ZİYARET GÜNLERİM</VueText
            >
          </div>
          <div
            :class="activeTab == 'orderDays' ? 'active-title' : ''"
            @click="setActiveTab('orderDays')"
          >
            <VueText
              :color="activeTab === 'orderDays' ? 'currentColor' : 'grey-30'"
              sizeLevel="5"
              weightLevel="3"
              >ÖN SİPARİŞ GÜNLERİM</VueText
            >
          </div>
        </div>
        <hr class="bottom" />

        <div class="days-table">
          <div class="day" v-for="(day, index) in data.days" :key="index">
            <div class="day-name">{{ day }}</div>
            <div class="day-check" v-for="(partner, i) in partners" :key="i">
              <VueIcon
                v-if="isChecked(day, partner)"
                :iconName="constants.icons.iconChecked.name"
                :width="constants.icons.iconChecked.width"
                :height="constants.icons.iconChecked.height"
              />
            </div>
          </div>
        </div>
        <div class="items-wrapper">
          <ul class="pmi-menu">
            <li v-for="item in items" :key="item.id" class="pmi-menu-item">
              <router-link
                :to="{ path: item.path }"
                @click.native="item.text === 'Güncel Fiyat Listesi' && dataLayer()"
              >
                <VueIcon
                  class="icon-style"
                  iconColor="grey"
                  :iconName="item.icon.name"
                  v-if="item.id === 3"
                />
                <VueIcon
                  class="icon-style"
                  iconColor="white"
                  :iconName="item.icon.name"
                  :width="item.icon.width"
                  :height="item.icon.height"
                  v-else
                />
                <span class="pmi-menu-text">{{ item.text }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3">Markalar</VueHeadline>
        <div class="products-wrapper">
          <BrandProduct
            v-for="product in products"
            :key="product.pmCode"
            :productName="product.name"
            :productPath="`pmi/brand/${product.brandId}`"
            :productImageSrc="product.image"
          ></BrandProduct>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueGridContainer from '@/components/shared/VueGridContainer/VueGridContainer.vue';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';

import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { Product } from '@/services/Api/index.js';
import StaticContent from '@/services/Api/staticContents';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import { User } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'BusinessPartnersHome',
  mixins: [gtmUtils],
  components: {
    VueHeadline,
    VueText,
    BrandMeetPerson,
    VueGridContainer,
    VueGridRow,
    VueGridCol,
    BrandProduct,
    VueIcon,
    BrandLogo,
  },
  beforeCreate() {
    Product.getProducts().then(res => {
      const {
        Data: { brands, teUser, teUserPhone, partner },
      } = res.data;
      this.partner = partner;
      this.products = brands;
      this.people.te.name = teUser ? teUser.trim().toLocaleUpperCase('tr-TR') : '-';
      this.people.te.tep = teUserPhone ? `tel:${teUserPhone.trim()}` : '';
    });
    StaticContent.getStaticContent('BusinessPartnerPMI').then(res => {
      if (res.data && res.data.Data && res.data.Data.content) {
        this.headContent = res.data.Data.content;
      }
    });
  },

  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    flexAlignment() {
      return FLEX_JUSTIFIES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    routes() {
      return {
        priceList: `${RoutesPmi.PriceList.path}`,
        productList: `${RoutesPmi.ProductList.path}`,
      };
    },
    items() {
      let res = [
        {
          id: 0,
          text: 'Güncel Fiyat Listesi',
          icon: ICON_VARIABLES['priceList'],
          path: `pmi/${RoutesPmi.PriceList.path}`,
        },
        {
          id: 1,
          text: 'Güncel Ürün Listesi',
          icon: ICON_VARIABLES['productList'],
          path: `pmi/${RoutesPmi.ProductList.path}`,
        },
        {
          id: 2,
          text: 'Faturalarım',
          icon: ICON_VARIABLES['sheetTable'],
          path: `pmi/${RoutesPmi.Bills.path}`,
        },
        {
          id: 3,
          text: 'Tek Tip Paket',
          icon: ICON_VARIABLES['packages'],
          path: `pmi/${RoutesPmi.PlainPackage.path}`,
        },
      ];
      if (!this.faturaButton) res.splice(2, 1);
      return res;
    },
  },
  created() {
    this.getRouteDays();
    this.getFaturaButtonStatus();
  },
  data() {
    return {
      headContent: null,
      activeTab: 'visitDays',
      faturaButton: null,
      categories: [],
      data: {
        days: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
      },
      partners: [],
      partner: {},
      products: [],
      people: {
        te: { title: 'PMI İş Geliştirme Sorumlun', name: '', tep: '' },
      },
      modalConfig: {
        isModalOpened: true,
        modalText: `2.200 Puanınız, 418 TL olarak Ülker İndirimi Cüzdanınıza eklenecektir.`,
        modalConfirmationText: 'Onaylıyor musunuz?',
        isDoubleButton: true,
        modalType: 'alert',
        alertType: 'error',
      },
    };
  },
  methods: {
    dataLayer() {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partner',
        partner: 'PMI',
      });
    },
    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            console.log(res.data.Data);
            res.data.Data.forEach(f => {
              if (f.partnerName == 'PMI') this.partners = [f];
            });

            console.log({ partners: this.partners });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },
    async getFaturaButtonStatus() {
      await StaticContent.getStaticContent('FaturalarButon').then(res => {
        this.faturaButton = !!+res.data.Data.content;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
  padding-bottom: 50px;
}
/deep/ .product {
  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  &-img {
    width: 100%;
    height: 235px;
    padding: 37px 15px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    padding: palette-space-level('10') 0;
    text-align: center;
    font-size: 24px;
  }
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
/deep/ .crumbs-holder {
  padding-left: 0;
}
.icon-style {
  display: inline-block;
  position: relative;
  left: -10px;
}
.landing {
  display: flex;
  padding: 40px 0 50px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;
    width: 100%;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      line-height: 1.27;
      padding-right: palette-space-level(10);
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}

.actions {
  display: flex;
  padding: palette-space-level(20) 0;
  z-index: 5;

  button:not(:first-child) {
    margin-left: palette-space-level(10);
  }
  button:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}

.items-wrapper {
  margin-top: palette-space-level(50);
  text-align-last: center;
  ul.pmi-menu {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li.pmi-menu-item {
      display: inline-block;
      a {
        display: block;
        height: 55px;
        line-height: 55px;
        padding: 0 28px;
        text-align: center;
        border-radius: palette-radius-level('15');
        background-image: linear-gradient(to left, #d20051, #ffa300);
        margin-right: 15px;
        text-decoration: none;
        color: #ffffff;
      }
      &:last-child {
        border-radius: palette-radius-level('15');
        background-image: linear-gradient(to left, #d20051, #ffa300);
        a {
          margin: 1px 1px;
          background: #f8f9f9 !important;
          background: transparent;
          color: #23303d;
        }
      }
    }
  }
}
.people-wrapper {
  padding: palette-space-level(30) 0;
  .person {
    margin-right: 21px;
    width: 50%;
  }
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.products-headline {
  padding: 0 0 30px 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.tabs {
  display: flex;
  z-index: 5;
  & > div {
    padding-bottom: palette-space-level(10);
    margin-right: palette-space-level(40);
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.active-title {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
}
.days-table {
  margin-top: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
}
.day {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  &-name {
    background-color: palette-color-level('grey', 10);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
</style>
